import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';

import { logout } from '../../actions/auth';

const Navbar = () => {

    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);
    const { permissions } = useSelector(state => state.auth.user);

    const handleLogout = () => {
        dispatch(logout());
    }

    return (
        <Fragment>
            <header id="header" className="header fixed-top d-flex align-items-center">

                <div className="d-flex align-items-center justify-content-between">
                    <a href="/" className="logo d-flex align-items-center">
                        <img className="logo-sm d-sm-none" src="assets/img/logo-admin.png" alt="" />
                        <img className="logo-lg d-none d-lg-block" src="assets/img/logo.png" alt="" />
                        <span className="d-none d-lg-block">OnTaskTech | <b className="admin-tag">Admin</b></span>
                    </a>
                    <i className="bi bi-list toggle-sidebar-btn"></i>
                </div>

                <nav className="header-nav ms-auto">
                    <ul className="d-flex align-items-center">
                        <li className="nav-item dropdown">
                            <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                                <i className="bi bi-bell"></i>
                                {
                                    0 != 0 ?
                                        <span className="badge bg-primary badge-number">{0}</span>
                                    :
                                        null
                                }                                
                            </a>

                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                                {
                                    0 > 0 ?
                                        <Fragment>
                                            <li className="dropdown-header">
                                                You have 4 new notifications
                                                <a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                                            </li>
                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>

                                            <li className="notification-item">
                                                <i className="bi bi-exclamation-circle text-warning"></i>
                                                <div>
                                                    <h4>Lorem Ipsum</h4>
                                                    <p>Quae dolorem earum veritatis oditseno</p>
                                                    <p>30 min. ago</p>
                                                </div>
                                            </li>

                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>

                                            <li className="notification-item">
                                                <i className="bi bi-x-circle text-danger"></i>
                                                <div>
                                                    <h4>Atque rerum nesciunt</h4>
                                                    <p>Quae dolorem earum veritatis oditseno</p>
                                                    <p>1 hr. ago</p>
                                                </div>
                                            </li>

                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>

                                            <li className="notification-item">
                                                <i className="bi bi-check-circle text-success"></i>
                                                <div>
                                                    <h4>Sit rerum fuga</h4>
                                                    <p>Quae dolorem earum veritatis oditseno</p>
                                                    <p>2 hrs. ago</p>
                                                </div>
                                            </li>

                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>

                                            <li className="notification-item">
                                                <i className="bi bi-info-circle text-primary"></i>
                                                <div>
                                                    <h4>Dicta reprehenderit</h4>
                                                    <p>Quae dolorem earum veritatis oditseno</p>
                                                    <p>4 hrs. ago</p>
                                                </div>
                                            </li>
                                        </Fragment>
                                    :
                                        <Fragment>
                                            <li className="dropdown-header">
                                                You have no new notifications
                                            </li>
                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>
                                            <li className="notification-item">
                                                <i className="bi bi-bell-slash text-muted"></i>
                                                <div>
                                                    <h4>No notifications at this time</h4>
                                                    <p>Check back later to see updates!</p>
                                                </div>
                                            </li>
                                        </Fragment>
                                }

                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li className="dropdown-footer">
                                    <a href="#">Show all notifications</a>
                                </li>
                            </ul>
                        </li>

                        <li className="nav-item dropdown pe-3">
                            <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                <img src="assets/img/avatar.png" alt="Profile" className="rounded-circle" />
                                <span className="d-none d-md-block dropdown-toggle ps-2">{user.name}</span>
                            </a>

                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li className="dropdown-header">
                                    <h6>{user.name + ' ' + user.lastname}</h6>
                                </li>

                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
                                        <i className="bi bi-gear"></i>
                                        <span>Settings</span>
                                    </a>
                                </li>

                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <a className="dropdown-item d-flex align-items-center" onClick={handleLogout} href="/">
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>Sign out</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>

            </header>
        </Fragment>
    )
}

export default Navbar;