import React, { Fragment, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Components
import Notification from './components/layout/Notification';
import Breadcrumb from './components/layout/Breadcrumb';
import Layout from './components/layout/Layout';
import PrivateRoute from './components/routing/PrivateRoute';
import ValidateSession from './components/routing/ValidateSession';
import NoMatch from './components/routing/NoMatch';

// Login
import Login from './views/auth/Login';

// Dashboard
import Dashboard from './views/dashboard/Dashboard';

// Employees
import Employees from './views/employees/Employees';
import AddEmployee from './views/employees/AddEmployee';
import EditEmployee from './views/employees/EditEmployee';

// Projects
import Projects from './views/projects/Projects';
import AddProject from './views/projects/AddProject';
import EditProject from './views/projects/EditProject';
import DetailsProject from './views/projects/DetailsProject';

// Configuración
import Profile from './views/configuration/profile/Profile';

// Redux
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

function App() {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	useEffect(() => {
		store.dispatch(loadUser());
	}, []);

	return (
		<Provider store={store}>
			<BrowserRouter>
				<Fragment>
					<Layout>								
						<Routes>
							{/* Login */}
							{/* <Route exact path="/" element={<ValidateSession><Login /></ValidateSession>}/> */}
							<Route exact path="/login" element={<ValidateSession><Login /></ValidateSession>}/>

							{/* Home */}
							<Route path="/" element={<PrivateRoute routePermission=''><Dashboard /></PrivateRoute>}/>
							<Route path="/home" element={<PrivateRoute routePermission=''><Dashboard /></PrivateRoute>}/>

							{/* Employees */}
							<Route path="/employees" element={<PrivateRoute routePermission='employees.view'><Employees /></PrivateRoute>}/>
							<Route path="/employees/add" element={<PrivateRoute routePermission='employees.add'><AddEmployee /></PrivateRoute>}/>
							<Route path="/employees/edit/:id" element={<PrivateRoute routePermission='employees.edit'><EditEmployee /></PrivateRoute>}/>

							{/* Projects */}
							<Route path="/projects" element={<PrivateRoute routePermission='projects.view'><Projects /></PrivateRoute>}/>
							<Route path="/projects/add" element={<PrivateRoute routePermission='projects.agregar'><AddProject /></PrivateRoute>}/>
							<Route path="/projects/edit/:id" element={<PrivateRoute routePermission='projects.edit'><EditProject /></PrivateRoute>}/>
							<Route path="/projects/details/:id" element={<PrivateRoute routePermission='projects.details'><DetailsProject /></PrivateRoute>}/>

							{/* Configuration */}
							<Route path="/configuration" element={<PrivateRoute routePermission='configuration.view'><Profile /></PrivateRoute>}/>
							
							{/* Not found anything*/}
							<Route path="*" element={<NoMatch />}/>
						</Routes>
					</Layout>
				</Fragment>
			</BrowserRouter>
		</Provider>
	);
}

export default App;