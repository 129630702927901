import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import Loader from './Loader';
import SideBar from './SideBar';
import Navbar from './Navbar';
import Notification from './Notification';
import Breadcrumb from './Breadcrumb';

const Layout = ({ children }) => {

    const { isAuthenticated } = useSelector(state => state.auth);
    const loader = useSelector(state => state.loader);

    const layoutAdmin = (
        <Fragment>
            {loader && <Loader></Loader>}
            <Navbar></Navbar>
            <SideBar></SideBar>
            <main id="main" className="main">
                <Breadcrumb />
                <Notification />
                { children }                
            </main>
        </Fragment>
    );

    const layoutGuest = (
        <Fragment>
            <Notification />
            { children }
        </Fragment>
    );

    if(isAuthenticated){
        return layoutAdmin;
    } else{
        return layoutGuest;
    }
}

export default Layout;