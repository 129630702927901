import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { buscarDashboard } from '../../actions/dashboard';

import DashboardAdministrador from './DashboardAdministrador';
import Construction from './Construction';

const Dashboard = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Home',
            path: '/home'
        }
    ];

    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        return () => {
            dispatch(buscarDashboard(null));
        }
    }, []);

    useEffect(() => {
        dispatch(editBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <div className="row mx-0">
            {
                user.type === 0 || user.type === '0' ?
                    <DashboardAdministrador />
                    :
                    <Construction />
            }
        </div>
    )
}

export default Dashboard;